import { Grid, Typography, useMediaQuery } from '@mui/material'
import { Formik, FormikContextType } from 'formik'
import * as React from 'react'

import { useNavigate } from 'react-router-dom'
import { colors, sizing } from '../../../constants'
import { useAppContext } from '../../../context/Provider'
import { useAuthService } from '../../../services/user'
import { userLoginStore } from '../../../store/userLoginStore'
import { isDormantAccount, isRtwUserOnly } from '../../../utils'
import { Button } from '../../button/button'
import { Form } from '../../form-controlled-field/form'
import { Password } from '../../form-controlled-field/password'
import { TextField } from '../../form-controlled-field/text'
import { FormTitle } from '../../form-controlled-field/title'
import { Row } from '../../grid'
import { Spacer } from '../../layout/spacer'
import Link from '../../link/src/Component'
import { schema } from './src/schema'

interface FormAuthLoginProps {
	fellowship?: boolean
}

export const FormAuthLogin: React.FC<FormAuthLoginProps> = ({ fellowship = false }) => {
	const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md')) ?? true
	const { userLogin, userOtpRequest, userFlwLogin } = useAuthService()
	const { setGlobalLoader, setSnackbar, setDialog } = useAppContext()
	const { formData } = userLoginStore()
	const navigate = useNavigate()

	React.useEffect(() => {
		// Hack: Need to find a better approach supporting other pages
		const accountReset = localStorage.getItem('account-reset')
		if (accountReset === 'true') {
			localStorage.removeItem('account-reset')
			setSnackbar({
				open: true,
				message: 'Password reset successful',
				severity: `success`,
			})
		}
	}, [])

	const hideValuesOnInput = ({ setStatus }: FormikContextType<any>) => {
		setStatus(null)
	}

	const handleError = (res: any, setStatus: Function, setSubmitting: Function) => {
		setGlobalLoader(false)
		setStatus({ error: res?.message })
		setSubmitting(false)
		setSnackbar({ open: true, message: res?.message, severity: 'error' })
	}

	const handleUnconfirmedAccount = (values: any) => {
		const otpPayload = { email: values.username, reason: 'registration' }
		const goToValidation = () => {
			userOtpRequest(otpPayload).then(() => {
				navigate(`${fellowship ? '/fellowship/validate' : '/validate'}`, {
					state: otpPayload,
				})
			})
		}

		setDialog({
			open: true,
			title: 'Account Confirmation',
			description: 'You have not completed the OTP process required to activate your account',
			onConfirm: goToValidation,
			setDialog,
			confirmTitle: 'Activate',
			variant: 'info',
			hideCancel: true,
		})
	}

	const redirectAfterLogin = (res: any, navMap: { [key: string]: string }) => {
		const userTypes: [string] = res?.user_type ?? []
		if (fellowship) {
			if (userTypes.indexOf('flw') !== -1) {
				navigate(navMap.fellowshipAccount)
			} else {
				navigate(navMap.fellowshipAccountUpdate)
			}
		} else {
			navigate(navMap.home)
		}
	}

	const handleNavigation = (res: any, setSubmitting: Function) => {
		const navMap: { [key: string]: string } = {
			login: '/login',
			updateDetails: '/account/update-details',
			fellowshipAccount: '/fellowship/account',
			fellowshipAccountUpdate: '/fellowship/account/update',
			confirmDetails: '/login/confirm-details',
			fellowshipConfirmDetails: '/fellowship/login/confirm-details',
			home: '/',
		}

		if (!res.access) {
			navigate(navMap.login)
		} else if (isRtwUserOnly(res?.user_type)) {
			navigate(navMap.updateDetails)
		} else if (isDormantAccount(res?.previous_login)) {
			navigate(fellowship ? navMap.fellowshipConfirmDetails : navMap.confirmDetails)
		} else if (!res?.confirmed && fellowship) {
			navigate(navMap.fellowshipConfirmDetails)
		} else {
			setGlobalLoader(false)
			setSubmitting(false)
			redirectAfterLogin(res, navMap)
		}
	}

	return (
		<React.Fragment>
			<Spacer height={60} />
			<Formik
				enableReinitialize={true}
				initialValues={formData}
				validationSchema={schema}
				validateOnChange={false}
				onSubmit={(values, { setStatus, setSubmitting }) => {
					setGlobalLoader(true)
					const _func = fellowship ? userFlwLogin : userLogin
					_func(values).then(async (res: any) => {
						setGlobalLoader(false)
						setSubmitting(false)

						if (res?.error) {
							handleError(res, setStatus, setSubmitting)
						} else if (res?.unconfirmed) {
							handleUnconfirmedAccount(values)
						} else {
							handleNavigation(res, setSubmitting)
							setStatus({ success: res?.message })
							setSnackbar({ open: true, message: res?.message, severity: 'success' })
						}
					})
				}}>
				{({ status, isSubmitting, setFieldValue }) => {
					return (
						<React.Fragment>
							<FormTitle title={`Log in`} status={status} />
							<Form onValuesChange={hideValuesOnInput}>
								<React.Fragment>
									<TextField
										trim
										name={`username`}
										label={`Email Address`}
										placeholder={`e.g johnathansmith@gmail.com`}
									/>
									<Password
										name={`password`}
										label={`Password`}
										placeholder={`enter your password`}
										hint={
											<Row justifyContent={`flex-start`} paddingY={2}>
												<Typography color={colors.divider}>Need help?</Typography>
												<Spacer width={sizing.themeSpacing} />
												<Link
													text={`Forgotten Password`}
													to={fellowship ? `/fellowship/reset-password` : `/reset-password`}
												/>
											</Row>
										}
										showStrength={false}
									/>
									<Grid container={true} spacing={1} sx={{ flexDirection: isMobile ? `row` : `row-reverse` }}>
										<Grid item={true} xs={12} md={6}>
											<Button type={`submit`} isLoading={isSubmitting} variant={`contained`} color={`secondary`}>
												Log in
											</Button>
										</Grid>
										<Grid item={true} xs={12} md={6}>
											<Button
												type={`button`}
												variant={`outlined`}
												color={`secondary`}
												onClick={() => navigate(fellowship ? `/fellowship/register` : `/register`)}>
												Create an account
											</Button>
										</Grid>
									</Grid>
								</React.Fragment>
							</Form>
						</React.Fragment>
					)
				}}
			</Formik>
			<Spacer height={200} />
		</React.Fragment>
	)
}
