import { pick } from 'lodash'
import { create } from 'zustand'
import { user } from '../constants/user'
import { User, UserLoginType } from '../types/user'

export interface FormStore {
	formData: UserLoginType
	setStoreData: (_data: Partial<User>) => void
	resetStoreData: () => void
}

export const initialState: FormStore = {
	formData: pick(user, ['username', 'password']),
	setStoreData: () => {},
	resetStoreData: () => {},
}

export const userLoginStore = create<FormStore>()((set) => ({
	...initialState,
	setStoreData: (data: any) =>
		set((state: Pick<FormStore, 'formData'>) => ({
			formData: { ...state.formData, ...data },
		})),
	resetStoreData: () => set(initialState),
}))
