import { FlwUser as FlwUserType, User as UserType } from '../types/user'

export const user: UserType = {
	first_name: '',
	last_name: '',
	email: '',
	contact_number: '',
	title: '',
	dob: '',
	address: '',
	location: '',
	municipality: '',
	country_citizenship: null,
	country: null,
	town: '',
	state: '',
	race: '',
	consent_given: false,
	terms_accepted: false,
	gender: '',
	password: '',
	username: '',
	confirmed: false,
	reference_source: '',
	reference_other: '',
	id_number: '',
	sa_citizen: false,
	income_over_250k: null,
	degree_name: '',
	user_type_raw: '',
	otp: '',
}

export const flw_user: FlwUserType = {
	id: '',
	first_name: '',
	last_name: '',
	email: '',
	contact_number: '',
	title: '',
	dob: '',
	country_citizenship: '',
	country: '',
	town: '',
	state: '',
	race: '',
	consent_given: false,
	terms_accepted: false,
	gender: '',
	password: '',
	username: '',
	confirmed: false,
	sa_citizen: false,
	degree_name: '',
	user_type_raw: '',
	university: '',
	degree_field: '',
	academic_year: '',
}
