import { Grid, Typography, useMediaQuery } from '@mui/material'
import { AcademicYear } from 'components/form-controlled-field/academic_year'
import { FieldOfStudy } from 'components/form-controlled-field/field_of_study'
import { University } from 'components/form-controlled-field/university'
import dayjs from 'dayjs'
import { Formik } from 'formik'
import { useStorage } from 'hooks/useStorage'
import * as React from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { sleep } from 'utils'
import { FormTitle } from '../../../../components/form-controlled-field/title'
import { DATE_FORMAT, sizing } from '../../../../constants'
import { gender, income, race } from '../../../../constants/options'
import { useAppContext } from '../../../../context/Provider'
import { useAuthService } from '../../../../services/user'
import { flwUserRegisterInfoStore, userRegisterInfoStore } from '../../../../store/userRegisterInfoStore'
import { flwUserRegisterStore, userRegisterStore } from '../../../../store/userRegisterStore'
import { AddressInterface } from '../../../../types/common'
import { Button } from '../../../button/button'
import { Checkbox } from '../../../form-controlled-field/checkbox'
import { Country } from '../../../form-controlled-field/country'
import { DateField } from '../../../form-controlled-field/date'
import { Form } from '../../../form-controlled-field/form'
import { Select } from '../../../form-controlled-field/select'
import { TextField } from '../../../form-controlled-field/text'
import { Spacer } from '../../../layout/spacer'
import { Link } from '../../../link'
import { Places } from '../../../places'
import { fellowshipSchema, schema } from './src/schema'

interface FormAuthRegisterInformationProps {
	fellowship?: boolean
}

export const FormAuthRegisterInformation = ({ fellowship = false }: FormAuthRegisterInformationProps) => {
	const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md')) ?? true
	const { getStorage } = useStorage()
	const { userCreateInformation, userFellowshipCreateInformation, userLogin } = useAuthService()
	const navigate = useNavigate()
	const { setGlobalLoader, setSnackbar, setDialog } = useAppContext()
	const { formData } = userRegisterInfoStore()
	const { flwFormData } = flwUserRegisterInfoStore()
	const { formData: registerFormData } = userRegisterStore()
	const { flwFormData: flwRegisterFormData } = flwUserRegisterStore()

	const { first_name, last_name, contact_number, email, password } = fellowship ? flwRegisterFormData : registerFormData

	useEffect(() => {
		if ((!first_name || !last_name || !contact_number || !email || !password) && !fellowship) {
			navigate(`/register`)
		}
	}, [])

	const handleTerms = (e: any) => {
		setDialog({
			open: true,
			title: `Please take note`,
			description: `You are leaving the 'Absa Consumer Financial Education website' and will be re-directed to the Absa website which communicates all of the terms and conditions`,
			onConfirm: () => window.open(`https://www.absa.co.za/legal-and-compliance/terms-of-use/`, '_blank'),
			setDialog,
			confirmTitle: 'Accept',
			variant: 'info',
		})
	}

	return (
		<React.Fragment>
			<Spacer height={60} />
			<Formik
				enableReinitialize
				initialValues={fellowship ? flwFormData : formData}
				validationSchema={fellowship ? fellowshipSchema : schema}
				onSubmit={(values: any, { setStatus, setSubmitting, setFieldError }) => {
					setGlobalLoader(true)
					const payload: any = {
						...values,
						first_name,
						last_name,
						contact_number,
						email,
						password,
						user_type_raw: fellowship ? 'flw' : 'cfe',
						username: email,
						dob: dayjs(values.dob).format(DATE_FORMAT),
						confirmed: false,
						degree_field: values.degree_field,
						country: values.country_citizenship,
						sa_citizen: values.country_citizenship === 'South Africa',
					}
					const response: any = fellowship ? userFellowshipCreateInformation(payload) : userCreateInformation(payload)
					response.then(async ({ error, message, errorMessageFieldNames }: any) => {
						if (errorMessageFieldNames?.length) {
							errorMessageFieldNames.map((field: string) => setFieldError(field, message))
						}

						setGlobalLoader(false)
						if (error) {
							setStatus({
								error: message,
							})
							setSubmitting(false)
							setSnackbar({
								open: true,
								message: `${message}`,
								severity: `error`,
							})
						} else {
							setStatus({
								success: `${message}`,
							})
							setSnackbar({
								open: true,
								message: `${message}`,
								severity: `success`,
							})
							await sleep(2000)
							setSubmitting(false)

							const _flw = await getStorage('flw_response')
							const _flw_registered = Object.keys(_flw)
							if (!fellowship && _flw_registered.length === 0) {
								navigate(`/validate`, {
									state: {
										email,
										reason: 'registration',
									},
								})
							} else {
								const login = await userLogin({
									username: flwFormData.username,
									password,
								})
								if (login?.error) {
									setSnackbar({
										open: true,
										message: login?.message,
										severity: `error`,
									})
									navigate(`/fellowship/login`)
								} else {
									navigate(`/fellowship/account`)
								}
							}
						}
					})
				}}>
				{({ values, status, isSubmitting, setFieldValue }) => {
					const southAfricanResident: boolean = values.country === `South Africa`
					const southAfrican: boolean = values.country_citizenship === `South Africa`
					const isFellowSA = fellowship && southAfrican
					const isNotFellowSA = !fellowship && southAfrican
					return (
						<React.Fragment>
							<FormTitle
								title={`Create an account`}
								subTitle={`Profile information`}
								nextStep={``}
								progress={95}
								status={status}
							/>
							<Form>
								<DateField name={`dob`} label={`Date of birth`} />
								{!fellowship && <Country name={`country`} label={`Country of residence`} placeholder={`Select country`} />}
								<Country name={`country_citizenship`} label={`Citizenship`} placeholder={`Select country`} />
								{fellowship && (
									<>
										<University
											name={`university`}
											label={`Which university do you study at?`}
											placeholder={`Select a university`}
										/>
										<FieldOfStudy
											name={`degree_field`}
											label={`In which field do you study currently?`}
											placeholder={`Select your field of study`}
										/>
										<Spacer height={8} />
										<TextField
											label={`What is the name of the degree you are studying?`}
											name={`degree_name`}
											placeholder={`Type the degree name`}
											plain
										/>
										<AcademicYear
											name={`academic_year`}
											label={`What academic year are you in?`}
											placeholder={`Select academic year`}
										/>
									</>
								)}
								{isFellowSA && <Select name='race' label='Ethnicity' placeholder='Select your ethnicity' options={race} />}
								{fellowship && <Select name={`gender`} label={`Gender`} placeholder={`Select gender`} options={gender} />}
								<Spacer height={8} />
								{!fellowship && (
									<>
										<Places
											label={`Residential address`}
											getAddress={({ address, town, stateName, municipality }: AddressInterface) => {
												setFieldValue(`address`, address)
												setFieldValue(`municipality`, municipality)
												setFieldValue(`town`, town)
												setFieldValue(`state`, stateName)
											}}
											placeholder={`Start typing address or town name`}
										/>
										<Spacer height={6} />
										<TextField name={`address`} placeholder={`Address`} plain disabled />
										<TextField name={`municipality`} placeholder={`Municipality`} plain disabled />
										<TextField name={`town`} placeholder={`Area or town`} plain disabled />
										<TextField name={`state`} placeholder={`State or Province`} plain disabled />
										<TextField name={`location`} placeholder={`Apartment (optional)`} plain />
									</>
								)}

								{isNotFellowSA && (
									<TextField
										name={`id_number`}
										label={`What is your South African ID Number`}
										placeholder={`Enter your SA ID Number`}
									/>
								)}
								{isNotFellowSA && southAfricanResident && (
									<Select
										name='income_over_250k'
										label='How much do you earn per year?'
										placeholder='Select your income range'
										options={income}
									/>
								)}
								{isNotFellowSA && (
									<Select name='race' label='Ethnicity' placeholder='Select your ethnicity' options={race} />
								)}
								{!fellowship && <Select name={`gender`} label={`Gender`} placeholder={`Select gender`} options={gender} />}
								<Checkbox
									name={`terms_accepted`}
									label={
										<Typography>
											I agree to the <Link onClick={handleTerms} to={`#`} text={`Terms and Conditions`} />
										</Typography>
									}
								/>
								<Spacer height={sizing.themeSpacing * 3} />
								<Grid container spacing={1} sx={{ flexDirection: isMobile ? `row` : `row-reverse` }}>
									<Grid item xs={12}>
										<Button type={`submit`} isLoading={isSubmitting} variant={`contained`} color={`secondary`}>
											Submit
										</Button>
									</Grid>
									<Grid item xs={12}>
										<Button
											type={`button`}
											variant={`outlined`}
											color={`secondary`}
											onClick={() => {
												navigate(fellowship ? `/fellowship/register` : `/register`)
											}}>
											Back
										</Button>
									</Grid>
								</Grid>
							</Form>
						</React.Fragment>
					)
				}}
			</Formik>
			<Spacer height={200} />
		</React.Fragment>
	)
}
