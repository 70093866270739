import App from 'App'
import 'assets/sass/global.scss'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import * as amplitude from '@amplitude/analytics-browser'

if (process.env.REACT_APP_AMPLITUDE_API_KEY) {
	amplitude.init(`${process.env.REACT_APP_AMPLITUDE_API_KEY}`, {
		autocapture: true,
		serverZone: 'EU',
	})
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(<App />)

reportWebVitals()
