import { GlobalProgress } from 'components/progress'
import { useAppContext } from 'context/Provider'
import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { useCFEAssessmentService } from 'services/cfe_assessment'
import { FullModuleSummary } from 'types/cfe_assessment'
import { Assessment } from './Component.assessment'
import { ModuleList } from './Component.moduleList'
import ScormAssessment from './scorm/Component.assessment.scorm'
import { track as amplitudeTrack } from '@amplitude/analytics-browser'

const MODULE_INDEX_PARAM = 'module_index'
const SCORM_COURSE_INDEX = 1

export default function PageAssessmentHome() {
	const { setGlobalLoader } = useAppContext()
	const { cfeFullModuleSummaries } = useCFEAssessmentService()
	const [moduleSummaries, setModuleSummaries] = React.useState<FullModuleSummary[] | null>(null)
	const [searchParams, setSearchParams] = useSearchParams()

	React.useEffect(() => {
		// Load data
		if (searchParams.get(MODULE_INDEX_PARAM) !== null && moduleSummaries !== null) {
			return
		}

		setGlobalLoader(true)
		cfeFullModuleSummaries()
			.then((data) => {
				setModuleSummaries(data)
			})
			.finally(() => {
				setGlobalLoader(false)
			})
	}, [searchParams])

	const updateCurrentModuleIndex = (index?: number) => {
		// Note: Move the questions to their own page
		if (index !== null) {
			if (index === SCORM_COURSE_INDEX) {
				const newUrl = new URL(window.location.href)
				newUrl.searchParams.set(MODULE_INDEX_PARAM, SCORM_COURSE_INDEX.toString())
				window.location.href = newUrl.toString()
			} else {
				setSearchParams({ ...setSearchParams, [MODULE_INDEX_PARAM]: index.toString() })
			}
		} else {
			searchParams.delete(MODULE_INDEX_PARAM)
			setSearchParams({ ...searchParams })
		}
	}

	const trackModuleClick = (_moduleSummary: FullModuleSummary) => {
		const eventProperties = {
			moduleName: _moduleSummary.module.name,
		}
		if (process.env.REACT_APP_AMPLITUDE_API_KEY) {
			amplitudeTrack('CFE Module Clicked', eventProperties)
		}
	}

	const currentModuleIndex = searchParams.get(MODULE_INDEX_PARAM)
	const currentModule = moduleSummaries === null || currentModuleIndex === null ? null : moduleSummaries[parseInt(currentModuleIndex)]

	if (moduleSummaries === null) {
		return <GlobalProgress isLoading />
	}

	console.log('CurrentModuleIndex: ', currentModuleIndex, currentModule)

	switch (currentModuleIndex) {
		case null: {
			return (
				<ModuleList
					modules={moduleSummaries}
					updateCurrentModuleIndex={updateCurrentModuleIndex}
					trackModuleClick={trackModuleClick}
				/>
			)
		}
		case '1': {
			return <ScormAssessment module={currentModule} updateCurrentModuleIndex={updateCurrentModuleIndex} />
		}
		default: {
			return <Assessment module={currentModule} updateCurrentModuleIndex={updateCurrentModuleIndex} />
		}
	}
}
